import React, { FC, ReactNode } from 'react';

import { AlertProps, IconButton, Link, Stack, SxProps, Typography } from '@mui/material';
import { CloseIcon } from 'icons';

import { Button } from '../Buttons';
import { AlertWrapper, iconsMap } from './Alert.styled';
import { AlertVariant, AlertVariantType } from './types';

type Action = {
  label: string;
  onClick?(): void;
  href?: string;
};

type Props = {
  title?: string;
  variant?: AlertVariantType;
  description?: ReactNode;
  children?: ReactNode | undefined;
  actions?: Action[];
  sx?: SxProps;
} & Pick<AlertProps, 'onClose'>;

export const Alert: FC<Props> = ({
  variant = AlertVariant.Transparent,
  actions = [],
  title,
  description,
  children,
  onClose,
  sx,
}) => {
  const Icon = iconsMap[variant];

  return (
    <AlertWrapper data-testid={`alert-${variant}`} variant={variant} sx={sx} role="alert">
      <Icon sx={{ fontSize: title ? 24 : 20 }} />

      <Stack alignItems="start">
        {title && (
          <Typography variant="natter-text-md" color="grey.900" component="h2" mb={1} fontWeight={600}>
            {title}
          </Typography>
        )}
        {description && (
          <Typography variant="natter-text-sm" color="text.secondary" component="span" whiteSpace="pre-wrap">
            {description}
          </Typography>
        )}

        {children}

        {actions.length > 0 && (
          <Stack direction="row" gap={3} mt={2}>
            {actions.map(({ label, href, onClick }, index) =>
              href ? (
                <Link key={index} href={href} sx={({ typography }) => ({ ...typography['natter-text-sm'] })}>
                  <Typography noWrap>{label}</Typography>
                </Link>
              ) : (
                <Button key={index} size="small" onClick={onClick}>
                  {label}
                </Button>
              )
            )}
          </Stack>
        )}
      </Stack>

      {onClose && (
        <IconButton aria-label="Close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      )}
    </AlertWrapper>
  );
};

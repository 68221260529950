import React from 'react';

import { type Components, type Theme } from '@mui/material';
import { CheckFilledIcon, ErrorFilledIcon, InfoFilledIcon, WarningFilledIcon } from 'icons';

import { blue, green, grey, orange, red } from '../palette';

export const MuiAlert: Components<Theme>['MuiAlert'] = {
  defaultProps: {
    variant: 'standard',
    iconMapping: {
      success: <CheckFilledIcon fontSize="inherit" />,
      error: <ErrorFilledIcon fontSize="inherit" />,
      warning: <WarningFilledIcon fontSize="inherit" />,
      info: <InfoFilledIcon fontSize="inherit" />,
    },
  },
  styleOverrides: {
    root: ({ theme: { spacing, typography, palette } }) => ({
      border: 'none',
      borderRadius: 0,
      padding: spacing(2),
      color: palette.grey[900],

      ...typography['natter-text-sm'],

      '& .MuiAlertTitle-root': {
        fontWeight: 600,
      },
    }),
    icon: ({ theme: { spacing } }) => ({
      fontSize: 20,
      marginRight: spacing(1),
      padding: 0,
      justifyContent: 'center',
    }),
    action: {
      paddingTop: 0,
      marginRight: 0,
    },
    message: {
      padding: 0,
    },
    standardSuccess: {
      backgroundColor: green[50],
      color: 'inherit',

      '& .MuiAlert-icon': {
        color: green[600],
      },
    },
    standardError: {
      backgroundColor: red[50],
      color: 'inherit',

      '& .MuiAlert-icon': {
        color: red[600],
      },
    },
    standardInfo: {
      backgroundColor: blue[50],

      '.MuiAlert-icon': {
        color: blue[600],
      },
    },
    standardWarning: {
      backgroundColor: orange[400],
      color: grey[800],

      '.MuiAlert-icon': {
        color: grey[800],
      },
    },
  },
};

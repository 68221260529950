import React, { CSSProperties, ChangeEvent, FC } from 'react';

import { InputAdornment, SxProps, TextField, TextFieldProps } from '@mui/material';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { SearchIcon } from 'icons';

export type SearchBoxProps = Pick<TextFieldProps, 'size' | 'variant'> & {
  placeholder?: string;
  onSearch?(searchedTerm: string): void;
  onSearchDebounced?(searchedTerm: string): void;
  backgroundColor?: CSSProperties['backgroundColor'];
  sx?: SxProps;
  'data-testid'?: string;
  defaultValue?: string;
};

export const SearchBox: FC<SearchBoxProps> = ({
  onSearch,
  onSearchDebounced,
  placeholder = 'Search',
  size = 'small',
  sx,
  ...props
}) => {
  const debounceChange = AwesomeDebouncePromise((searchedTerm: string) => {
    onSearchDebounced?.(searchedTerm);
  }, 500);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const searchedTerm: string = event.target.value.trim();
    onSearch?.(searchedTerm);
    debounceChange(searchedTerm);
  };

  return (
    <TextField
      fullWidth
      size={size}
      onChange={handleOnChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon fontSize={size} />
          </InputAdornment>
        ),
        sx: ({ spacing, palette }) => ({
          paddingLeft: spacing(1),
          '& > .MuiInputAdornment-root': {
            paddingRight: spacing(0.5),
          },
          '&.MuiInputBase-sizeSmall': {
            height: 32,
          },
          '&.MuiInputBase-root': {
            backgroundColor: palette.mode === 'light' ? palette.grey[50] : palette.common.black,
          },
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: palette.mode === 'light' ? palette.grey[50] : palette.common.black,
          },
          '&.Mui-focused': {
            backgroundColor: 'unset',
          },
        }),
      }}
      inputProps={{ 'aria-label': 'Search' }}
      placeholder={placeholder}
      sx={sx}
      data-testid={props['data-testid']}
      {...props}
    />
  );
};

import React, { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Stack, Typography } from '@mui/material';
import { useBreakpoints, useToggleState } from 'common/hooks';
import { ChevronDownIcon, ChevronUpIcon } from 'icons';

import { Button } from '../Buttons';

type Props = {
  text: string;
};

export const ShowMoreBox: FC<Props> = ({ text }) => {
  const { t } = useTranslation('common', { keyPrefix: 'showMoreBox' });
  const { isMobile, isTablet } = useBreakpoints();
  const maxShortTextLength = (() => {
    if (isMobile) return 125;
    if (isTablet) return 250;
    return 500;
  })();
  const isShortText = text.length < maxShortTextLength;
  const longTextRef = useRef<HTMLSpanElement>(null);

  const [isExpanded, toggleExpanded] = useToggleState(false);
  const longTextToDisplay = isExpanded ? text : `${text.slice(0, maxShortTextLength)}...`;

  useEffect(() => {
    if (isExpanded) {
      longTextRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [isExpanded]);

  return isShortText ? (
    <Typography sx={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}>{text}</Typography>
  ) : (
    <Stack gap={2}>
      <Typography sx={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap' }} ref={longTextRef}>
        {longTextToDisplay}
      </Typography>
      <Box>
        {isExpanded ? (
          <Button
            onClick={toggleExpanded}
            variant="text-no-padding"
            endIcon={<ChevronUpIcon />}
            data-testid="ShowMoreBox-button-collapse"
          >
            {t('showLessButtonLabel')}
          </Button>
        ) : (
          <Button
            onClick={toggleExpanded}
            variant="text-no-padding"
            endIcon={<ChevronDownIcon />}
            data-testid="ShowMoreBox-button-expand"
          >
            {t('showMoreButtonLabel')}
          </Button>
        )}
      </Box>
    </Stack>
  );
};

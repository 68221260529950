import { createApi } from '@reduxjs/toolkit/query/react';
import { EventImageApiResponse } from 'domain/event';
import { EventSeries } from 'domain/event/EventSeries';
import {
  mapEventSeriesDetailsFromApi,
  mapEventSeriesToUpdateEventSeriesApiPayload,
  mapEventSeriesWizardFormValuesToApi,
} from 'event/mappers';
import { getApiBaseUrl } from 'modules/api/utils';
import { generateFormData } from 'store/utils';

import axiosBaseQuery from '../_axiosBaseQuery';
import type {
  ConvertSingleEventToSeriesPayload,
  CreateEventPayload,
  CreateEventSeriesResponse,
  DeleteEventsFromSeriesArgs,
  UpdateEventPayload,
  UpdateEventSeriesImagePayload,
  UpdateEventSeriesQuestionsPayload,
} from './types';
import { omitUndefined } from './utils';

export const eventSeriesApi = createApi({
  reducerPath: 'eventSeriesApi',
  baseQuery: axiosBaseQuery({ baseURL: getApiBaseUrl('v1') }),
  endpoints: (builder) => ({
    getEventSeriesDetails: builder.query<EventSeries, { seriesId: number }>({
      keepUnusedDataFor: 0,
      query: ({ seriesId }) => ({
        url: `/eventSeries/${seriesId}`,
      }),
      transformResponse: mapEventSeriesDetailsFromApi,
    }),
    createEventSeries: builder.mutation<CreateEventSeriesResponse, CreateEventPayload>({
      query: (data) => ({
        url: '/eventSeries',
        method: 'POST',
        data: mapEventSeriesWizardFormValuesToApi(data),
      }),
    }),
    updateEventSeries: builder.mutation<void, UpdateEventPayload>({
      query: ({ seriesId, ...data }) => ({
        url: `/eventSeries/${seriesId}`,
        method: 'PUT',
        data: mapEventSeriesWizardFormValuesToApi(data),
      }),
    }),
    convertSingleEventToSeries: builder.mutation<void, ConvertSingleEventToSeriesPayload>({
      query: ({ id, ...data }) => ({
        url: `/eventSeries/withEvent/${id}`,
        method: 'POST',
        data: mapEventSeriesWizardFormValuesToApi(data),
      }),
    }),
    deleteEventSeries: builder.mutation<void, { seriesId: number }>({
      query: ({ seriesId }) => ({
        url: `/eventSeries/${seriesId}`,
        method: 'DELETE',
      }),
    }),
    deleteEventsFromSeries: builder.mutation<void, DeleteEventsFromSeriesArgs>({
      query: ({ eventSeries, eventIdsToRemove }) => {
        if (eventSeries.events.length === eventIdsToRemove.length) {
          return {
            url: `/eventSeries/${eventSeries.id}`,
            method: 'DELETE',
          };
        }
        return {
          url: `/eventSeries/${eventSeries.id}`,
          method: 'PUT',
          data: mapEventSeriesToUpdateEventSeriesApiPayload({ data: eventSeries, eventIdsToRemove }),
        };
      },
    }),
    updateEventSeriesQuestions: builder.mutation<void, UpdateEventSeriesQuestionsPayload>({
      query: ({ seriesId, questions }) => ({
        url: `/eventSeries/${seriesId}/questions`,
        method: 'POST',
        data: questions,
      }),
    }),
    postEventSeriesImage: builder.mutation<EventImageApiResponse, UpdateEventSeriesImagePayload>({
      query: ({ seriesId, ...params }) => ({
        url: `/eventSeries/${seriesId}/images`,
        headers: { 'Content-Type': 'multipart/form-data' },
        method: 'POST',
        data: generateFormData(omitUndefined(params)),
      }),
    }),
  }),
});

export const {
  useCreateEventSeriesMutation,
  useGetEventSeriesDetailsQuery,
  useDeleteEventsFromSeriesMutation,
  useUpdateEventSeriesQuestionsMutation,
} = eventSeriesApi;

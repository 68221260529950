import { createApi } from '@reduxjs/toolkit/query/react';
import { Answer, CreateAnswerRequest } from 'domain/question';
import { getApiBaseUrl } from 'modules/api/utils';

import axiosBaseQuery from '../_axiosBaseQuery';
import type { GetAnswersRequest } from './types';

export const answerApi = createApi({
  reducerPath: 'answerApi',
  baseQuery: axiosBaseQuery({ baseURL: getApiBaseUrl('v1') }),
  endpoints: (builder) => ({
    getAnswer: builder.query<Answer[], GetAnswersRequest>({
      query: (params) => ({
        url: `/answer`,
        params,
      }),
    }),
    postAnswer: builder.mutation<void, CreateAnswerRequest>({
      query: (data) => ({
        url: `/answer`,
        method: 'POST',
        data,
      }),
    }),
    postAnswers: builder.mutation<void, CreateAnswerRequest[]>({
      queryFn: async (data, { dispatch }) => {
        await Promise.all(data.map((answer) => dispatch(answerApi.endpoints.postAnswer.initiate(answer)).unwrap()));

        return { data: undefined };
      },
    }),
  }),
});

export const { useGetAnswerQuery, usePostAnswerMutation } = answerApi;

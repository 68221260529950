import { createAsyncThunk } from '@reduxjs/toolkit';
import { assert } from 'common/utils';
import { EventWizardFormSerializedValues } from 'pages/EventWizard/validation';
import { RootState } from 'store';
import { selectMyCommunityId } from 'store/apis/community';
import { eventApi, eventSeriesApi, selectActiveEventDetails } from 'store/apis/event';
import { handleQueryError } from 'store/utils';

import { selectEditEventId } from '../eventWizard.selectors';
import { updateImages } from './updateImages';
import { updateQuestions } from './updateQuestions';

export const updateEvent = createAsyncThunk<number | undefined, EventWizardFormSerializedValues>(
  '[Event Wizard] update button clicked',
  async (payload, { dispatch, getState }) => {
    const state = getState() as RootState;
    const eventId = selectEditEventId(state);
    const originalEvent = selectActiveEventDetails(state);
    const communityId = selectMyCommunityId(state);

    try {
      assert(eventId, 'Event ID is not present');
      assert(originalEvent, 'Active Event details are not present');

      const relatedImageIds = await dispatch(
        updateImages({ eventId, seriesId: payload.seriesId, values: payload })
      ).unwrap();

      await dispatch(updateQuestions({ eventId, seriesId: payload.seriesId, values: payload })).unwrap();

      if (payload.seriesId) {
        await dispatch(eventSeriesApi.endpoints.updateEventSeries.initiate({ relatedImageIds, ...payload }));
      } else if (payload.schedule.length > 1) {
        assert(communityId, 'Community ID is not present');
        await dispatch(
          eventSeriesApi.endpoints.convertSingleEventToSeries.initiate({
            communityId,
            id: eventId,
            relatedImageIds,
            ...payload,
          })
        );
      } else {
        await dispatch(eventApi.endpoints.updateEvent.initiate({ id: eventId, relatedImageIds, ...payload }));
      }
      return eventId;
    } catch (error) {
      handleQueryError(
        error,
        dispatch,
        'eventWizardListener.updateEventButton.clicked',
        'Error occurred while updating the Event'
      );
    }
  }
);

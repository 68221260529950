import { Accordion, AccordionDetails, AccordionSummary, styled } from '@mui/material';
import { omitForwardedProps } from 'common/utils';

type WithSizeProp = {
  size: 'small' | 'medium';
};

export type ExpandableBoxVariants = 'text' | 'outlined';

export const ExpandableBoxWrapper = styled(Accordion, {
  shouldForwardProp: omitForwardedProps('size', 'boxVariant'),
})<WithSizeProp & { boxVariant: ExpandableBoxVariants }>(({ theme: { shape }, size, boxVariant }) => ({
  '&.MuiPaper-root': {
    borderRadius: size === 'small' ? shape.borderRadius : shape.borderRadius * 2,
    ...(boxVariant === 'text' && {
      '&.MuiPaper-root': {
        boxShadow: 'none',
      },
      '& .MuiAccordionSummary-root': {
        padding: 0,
      },
    }),
  },
  '& .MuiAccordionSummary-root.Mui-focusVisible': {
    backgroundColor: 'initial',
  },
}));

export const ExpandableBoxSummary = styled(AccordionSummary, {
  shouldForwardProp: omitForwardedProps('size', 'isExpanded', 'showDivider'),
})<WithSizeProp & { isExpanded: boolean; showDivider: boolean }>(
  ({ theme: { spacing, typography }, size, isExpanded, showDivider }) => ({
    padding: size === 'small' ? spacing(2) : spacing(3),
    paddingBottom: showDivider || !isExpanded ? undefined : 0,
    flexDirection: 'row-reverse',
    minHeight: 'unset',
    gap: spacing(1),
    alignItems: 'start',

    '& .MuiAccordionSummary-content': {
      ...typography['natter-text-xl'],

      margin: 0,
      gap: spacing(2),
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      color: 'inherit',

      '&.Mui-expanded': {
        transform: 'rotate(90deg)',
      },
    },

    ...(isExpanded && {
      transition: 'padding 0.2s',
    }),
  })
);

export const ExpandableBoxContent = styled(AccordionDetails, {
  shouldForwardProp: omitForwardedProps('size', 'showDivider', 'variant'),
})<{ showDivider: boolean; variant: ExpandableBoxVariants } & WithSizeProp>(
  ({ theme: { spacing, palette }, size, showDivider, variant }) => ({
    padding: size === 'small' ? spacing(2) : spacing(2, 3),
    ...(showDivider && {
      borderTop: '1px solid',
      borderColor: palette.divider,
    }),
    ...(variant === 'text' && {
      padding: 0,
    }),
  })
);

import React, { ComponentType, FC } from 'react';

import { PaletteMode, ThemeProvider } from '@mui/material';
import { theme as createTheme } from 'common/theme';

type Props = {
  mode?: PaletteMode;
};

export const NatterThemeProvider: FC<Props> = ({ children, mode = 'light' }) => (
  <ThemeProvider theme={createTheme(undefined, mode)}>{children}</ThemeProvider>
);

export const withTheme =
  <P extends object>(WrappedComponent: ComponentType<P>): FC<P> =>
  (props) => (
    <NatterThemeProvider>
      <WrappedComponent {...props} />
    </NatterThemeProvider>
  );

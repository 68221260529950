import { createAsyncThunk } from '@reduxjs/toolkit';
import { assert } from 'common/utils';
import { mapAllEventWizardQuestionsToApi } from 'event/mappers';
import { EventWizardFormSerializedValues } from 'pages/EventWizard/validation';
import { RootState } from 'store';
import { eventApi, eventSeriesApi, selectActiveEventQuestions } from 'store/apis/event';

export const updateQuestions = createAsyncThunk<
  void,
  { eventId: number; seriesId?: number; values: EventWizardFormSerializedValues }
>('[Event Wizard] Update event questions', async ({ eventId, seriesId, values }, { dispatch, getState }) => {
  const state = getState() as RootState;
  const originalQuestions = selectActiveEventQuestions(state);
  const questions = mapAllEventWizardQuestionsToApi(values);

  if (!(originalQuestions?.length || questions.length)) return;

  if (seriesId) {
    await dispatch(eventSeriesApi.endpoints.updateEventSeriesQuestions.initiate({ seriesId, questions })).unwrap();
  } else {
    assert(eventId, 'Event ID is not present');
    await dispatch(eventApi.endpoints.createEventQuestions.initiate({ id: eventId, questions })).unwrap();
  }
});

import { type Components, type Theme, alpha } from '@mui/material';

import { darkBlue } from '../palette';
import { shadows } from '../shadows';

export const MuiDialog: Components<Theme>['MuiDialog'] = {
  styleOverrides: {
    root: {
      '& .MuiBackdrop-root': {
        backgroundColor: alpha(darkBlue, 0.49),
      },
    },
    paper: ({ theme: { spacing } }) => ({
      borderRadius: spacing(1),
      border: 'none',
      boxShadow: shadows['ligh-shadow-8'],
    }),
    paperWidthXs: {
      maxWidth: 400,
    },
    paperWidthSm: {
      maxWidth: 600,
    },
    paperWidthMd: {
      maxWidth: 752,
    },
    paperWidthLg: {
      maxWidth: 968,
    },
    paperFullScreen: {
      maxWidth: '100%',
      borderRadius: 0,
    },
  },
};
